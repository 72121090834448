import {Box, BoxProps} from "@mui/material";
import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";
import {IPortfolioQuestion} from "../../../../../types/portfolio/IPortfolio";

interface IPropsCustom {
	question: IPortfolioQuestion,
}

export type IProps = BoxProps & IPropsCustom;

const useStyles = makeStyles()((theme) => ({
	questionContent: {
		display: "flex",
		gap: "5px"
	},
	label: {
		cursor: "pointer",
		color: theme.palette.secondary.main,
		fontWeight: 600
	},
	bodyContainer: {
		overflow: "hidden",
		transition: "all .5s ease-in"
	},
	wysiwygBody: {
		"& p": {
			marginTop: "10px",
			opacity: "0.8"
		},
		"& p:nth-last-child(1)": {
			marginBottom: 0
		}
	}
}));

export const QuestionContent: FC<IProps> = ({className, question, ...props}) => {
	const {classes, cx} = useStyles();

	if (!question.content)
		return <></>;

	return (
		<Box
			className={cx(classes.questionContent, className)}
			{...props}
		>
			<Box className={classes.bodyContainer}>
				<WYSIWYG className={classes.wysiwygBody}>{question.content}</WYSIWYG>
			</Box>
		</Box>
	);
};
