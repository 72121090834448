import {FC, useEffect} from "react";

import {IUsePortfolioReturnProp, usePortfolioQuestions} from "./usePortfolioQuestions";

export interface IProps {
	portfolioSlug?: string,
	courseSlug?: string,
	children?: (e: IUsePortfolioReturnProp) => JSX.Element,
}

export const PortfolioQuestionsDataProvider: FC<IProps> = ({children, courseSlug, portfolioSlug}) => {
	const dataHook = usePortfolioQuestions(courseSlug, portfolioSlug)
	const {portfolioQuestions, getPortfolioQuestions, setPortfolioQuestions} = dataHook;

	useEffect(() => {
		if(portfolioSlug && courseSlug)
			getPortfolioQuestions(courseSlug, portfolioSlug);
	}, [portfolioSlug, courseSlug])

	useEffect(() => {
		if(portfolioQuestions){
			setPortfolioQuestions(portfolioQuestions)
		}
	}, [portfolioQuestions])

	return children?.(dataHook)
		?? <div>Please implement children to handle portfolio {"...loading"}</div>
}

export default PortfolioQuestionsDataProvider;