import {FC, ReactElement, useContext, useEffect} from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {AuthContext} from "@plumeuk/shapeshift-identity";

interface IProps {
	children: ReactElement,
}

export const RequireAuth: FC<IProps> = ({children}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const {authenticated} = useContext(AuthContext);

	useEffect(() => {
		if(!authenticated){
			navigate(`/?redirectPath=${encodeURIComponent(location.pathname)}&redirectSearch=${encodeURIComponent(location.search)}`);
		}
	}, [authenticated])

	if (!authenticated)
		return <Navigate to="/" state={{from: location}} />;

	return children;
}