import {Box, BoxProps, Typography, Button} from "@mui/material";
import {FC, useCallback, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";
import {useNavigate} from "react-router-dom";
import {IQuiz} from "@plumeuk/shapeshift-types";

const useStyles = makeStyles()((theme) => ({
	quizStartSummary: {
		padding: "40px 0"
	},
	quizStartTitle: {
	},
	quizStartSubtitle: {
	},
	startBtn: {
		marginTop: "30px",
		width: "127px",
		height: "52px",
		fontSize: "16px",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	content: {
		"& p, & ul": {
			lineHeight: "34px",
			fontSize: "20px",
			[theme.breakpoints.down("sm")]: {
				lineHeight: "25px",
				fontSize: "18px"
			}
		},
		"& ul": {
			textAlign: "left"
		}
	}
}));

interface IProps extends BoxProps {
	quiz?: IQuiz,
	onContinueActionOverride?: () => true
}

export const QuizStartSummary: FC<IProps> = ({quiz, className, onContinueActionOverride, ...props}) => {
	const {classes, cx} = useStyles();
	const navigate = useNavigate();
	const handleStartAction = useCallback((): void => {
		navigate("active");
	}, [navigate]);

	useEffect(() => {
		if(quiz && quiz.complete)
			navigate("complete");
	}, [quiz])

	return (
		<Box className={cx(classes.quizStartSummary, className)} {...props}>
			<Typography variant="h1" className={classes.quizStartTitle}>{quiz?.title}</Typography>
			<Typography variant="h4" className={classes.quizStartSubtitle}>{quiz?.subtitle}</Typography>
			<WYSIWYG className={classes.content}>
				{quiz?.content ?? ""}
			</WYSIWYG>
			<Button
				className={classes.startBtn}
				onClick={() => onContinueActionOverride?.() ?? handleStartAction()}
			>
				Start
			</Button>
		</Box>
	)
}