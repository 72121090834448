import {APIState, FetchRequestStruct, useApi} from "@plumeuk/shapeshift-identity";
import {IPortfolioAttempt, IPortfolioAttemptStatus} from "../types/portfolio/IPortfolioAttempt"
export type APIRequestConfig<R> = Omit<FetchRequestStruct<R>, "url">;

type S = IPortfolioAttempt;
type R = string;
type G = (courseSlug: string, portfolioSlug: string, learnerId: number, status: IPortfolioAttemptStatus, config?: APIRequestConfig<R>) => void;

export type IUseSetPortfolioAdminReviewReturnProp = {
	response?: S,
	apiResult: APIState<S>,
	setReview: G
}

export const useSetPortfolioAdminReview = (): IUseSetPortfolioAdminReviewReturnProp => {
	const [apiResult, getData] = useApi<S>(null);

	const setReview: G = (courseSlug, portfolioSlug, learnerId, status, config) =>
		getData({
			...(config ?? {}),
			url: "/api/portfolios/review/" + courseSlug + "/" + portfolioSlug + "/" + learnerId + "/" + status,
			method: "PUT",
			cache: false
		});

	return {response: apiResult.data, apiResult, setReview};
}