import {FC, useEffect} from "react";
import {IPortfolioAdminReviewEntry} from "../types/portfolio/IPortfolioAdminEntry";
import {IUsePortfolioAdminReviewEntryReturnProp, usePortfolioAdminReviewEntry} from "../hooks/usePortfolioAdminReviewEntry";

type S = IPortfolioAdminReviewEntry;

export interface IProps {
	children?: (e: IUsePortfolioAdminReviewEntryReturnProp) => JSX.Element,
	portfolioSlug?: string,
	courseSlug?: string,
	learnerId?: number,
	data?: S,
}

export const PortfolioAdminReviewEntryDataProvider: FC<IProps> = ({children, courseSlug, portfolioSlug, learnerId, data}) => {
	const dataHook = usePortfolioAdminReviewEntry(courseSlug ?? "", portfolioSlug ?? "", learnerId ?? 0, data)
	const {portfolioAdminEntry, getPortfolioAdminReviewEntry} = dataHook;

	useEffect(() => {
		getPortfolioAdminReviewEntry()
	}, [courseSlug, portfolioSlug, learnerId])

	return children?.(dataHook)
		?? <div>Please implement children to handle SubmissionsPage {portfolioAdminEntry?.portfolio.id ? "...loading" : ""}</div>
}

export default PortfolioAdminReviewEntryDataProvider;