import {APIState, useApi} from "@plumeuk/shapeshift-identity";
import {useEffect} from "react";
import {APIRequestConfig} from "@plumeuk/shapeshift-common/types/ICommon";
import {IPortfolioAdminReviewEntry} from "../types/portfolio/IPortfolioAdminEntry";

type S = IPortfolioAdminReviewEntry;
type R = string | {populate: string[]};
type G = (courseSlugOverride?: string, portfolioSlugOverride?: string, learnerIdOverride?: number, configOverride?: APIRequestConfig<R>) => void;

export type IUsePortfolioAdminReviewEntryReturnProp = {
	portfolioAdminEntry?: S,
	apiResult: APIState<S>,
	getPortfolioAdminReviewEntry: G,
	setPortfolioAdminEntry: (portfolioAdminEntry: S) => void
}

const getUrl = (courseSlug: string, portfolioSlug: string, learnerId: number): string => {
	return `/api/portfolios/review/${courseSlug}/${portfolioSlug}/${learnerId}`
}

export const usePortfolioAdminReviewEntry = (courseSlug: string, portfolioSlug: string, learnerId: number, data?: S): IUsePortfolioAdminReviewEntryReturnProp => {
	const [apiResult, getData, setPortfolioAdminEntry] = useApi<S>(getUrl(courseSlug, portfolioSlug, learnerId));

	const getPortfolioAdminReviewEntry: G = (courseSlugOverride, portfolioSlugOverride, learnerIdOverride, configOverride) => {
		const _learnerId = learnerIdOverride ?? learnerId;
		const _portfolioSlug = portfolioSlugOverride ?? portfolioSlug;
		const _courseSlug = courseSlugOverride ?? courseSlug;

		if(!_courseSlug || !_learnerId || !_portfolioSlug) return;


		const url = getUrl(courseSlug, portfolioSlug, learnerId);

		getData(configOverride ? {...configOverride, url} : url);
	}

	useEffect(() => data && setPortfolioAdminEntry(data), [data])

	return {portfolioAdminEntry: apiResult.data, apiResult, getPortfolioAdminReviewEntry, setPortfolioAdminEntry};
}