
import ReactPDF, {Document, Page, Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {FC, useCallback, useEffect, useState} from "react";
import {ICourseCertificate} from "@plumeuk/shapeshift-types/ICourse";
import {buildPngFromSvg} from "@plumeuk/shapeshift-common/common";

interface IPropsCustom {
	certificate: ICourseCertificate
}

export type IProps = Omit<ReactPDF.Document["props"], "children"> & IPropsCustom;

const styles = StyleSheet.create({
	page: {
		fontFamily: "Helvetica",
		flexDirection: "column",
		backgroundColor: "#ffffff",
		textAlign: "center",
		fontSize: 12
	},
	section: {
		justifyContent: "space-around",
		margin: 10,
		padding: 10,
		flexGrow: 1,
		lineHeight: 1.5
	},
	printedName: {
		fontFamily: "Helvetica-Bold",
		fontSize: 50,
		fontWeight: 900
	},
	h2: {
		fontSize: 20
	},
	institution: {
		paddingTop: 20,
		color: "#d1d1d1",
		fontFamily: "Helvetica-Bold"
	},
	backgroundImg:{
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		width: "100%",
		height: "100%",
		objectFit: "cover"
	},
	centerSection: {
		margin: 50
	},
	bottomSection: {
		flexDirection: "row",
		color: "white"
	},
	linedSection: {
		flexGrow: 1
	},
	linedSectionValueContainer: {
		borderBottom: "1px solid white"
	},
	linedSectionValue: {
		alignSelf: "center"
	},
	linedSectionLabelContainer: {
	},
	linedSectionLabel: {
		marginTop: 5,
		letterSpacing: 2,
		fontSize: 12,
		alignSelf: "center"
	},
	spacer: {
		width: 20
	}
});

export const CourseCertificateReactPDFDocument: FC<IProps> = ({certificate, ...props}) => {

	const [imgSrc, setImgSrc] = useState<string>("");

	const svgToPng = useCallback(async (url: string) => {
		const mSrc = await buildPngFromSvg(url)
		setImgSrc(mSrc);
	}, []);


	useEffect(() => {
		const isSrcSvg = certificate?.backgroundUrl && certificate?.backgroundUrl?.endsWith(".svg");
		if (isSrcSvg) {
			svgToPng(certificate.backgroundUrl ?? "");
		}
	}, [certificate]);

	return <Document {...props}>
		<Page size={"LETTER"} orientation="landscape" style={styles.page}>
			<Image style={styles.backgroundImg} src={!imgSrc ? certificate.backgroundUrl : imgSrc} />
			<View style={styles.section}>
				<Text style={[styles.h2, styles.institution]}>{certificate.institution}</Text>
			</View>

			<View style={[styles.section, styles.centerSection]}>
				<Text style={styles.h2}>Certificate of Completion</Text>
				<Text>is hereby granted to</Text>
				<Text style={styles.printedName}>{certificate.printedName}</Text>
				<Text>to certify completion of</Text>
				<Text style={styles.h2}>{certificate.printedTitle}</Text>
			</View>

			<View style={[styles.section, styles.bottomSection]}>
				<View style={styles.linedSection}></View>

				<View style={styles.linedSection}>
					<View style={styles.linedSectionValueContainer}>
						<Text style={styles.linedSectionValue}>{certificate.completionDate.toDateString()}</Text>
					</View>
					<View style={styles.linedSectionLabelContainer}>
						<Text style={styles.linedSectionLabel}>Date</Text>
					</View>
				</View>

				<View style={styles.spacer}></View>

				{certificate.instructor && <>
					<View style={styles.linedSection}>
						<View style={styles.linedSectionValueContainer}>
							<Text style={styles.linedSectionValue}>{certificate.instructor}</Text>
						</View>
						<View style={styles.linedSectionLabelContainer}>
							<Text style={styles.linedSectionLabel}>Instructor</Text>
						</View>
					</View>
					<View style={styles.spacer}></View>
				</>}

				{certificate.expiryDate && <View style={styles.linedSection}>
					<View style={styles.linedSectionValueContainer}>
						<Text style={styles.linedSectionValue}>{certificate.expiryDate.toDateString()}</Text>
					</View>
					<View style={styles.linedSectionLabelContainer}>
						<Text style={styles.linedSectionLabel}>Expiry date</Text>
					</View>
				</View>}

				<View style={styles.linedSection}></View>
			</View>
		</Page>
	</Document>
};
