import {Box, Button, LinearProgress, Typography} from "@mui/material";
import {FC, useContext, useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AuthContext, useApi} from "@plumeuk/shapeshift-identity";
import {LoginForm} from "@plumeuk/shapeshift-common/login";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {palette} from "../../constants";

const useStyles = makeStyles()((theme) => ({
	loginPage:{
		backgroundColor: theme.palette.background.default,
		minHeight: "100%",
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		alignItems: "center",
		"& [ class*='MuiInput']:before": {
			border: "none!important"
		},
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			backgroundColor: theme.palette.background.paper,
			width: "100%",
			padding: "5px"
		},
		"& input": {
			border: "none"
		}
	},
	container: {
		marginTop: "15px",
		margin: "auto",
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column-reverse"
		}
	},
	imgContainer: {
		textAlign: "center",
		width: "50%",
		margin: "auto",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "30px"
		},
		"& img": {
			[theme.breakpoints.down("sm")]: {
				width: "80px"
			}
		}
	},
	loginForm: {
		maxWidth: "400px",
		marginTop: "15px"
	},
	copy: {
		color: palette.darkGrey,
		textAlign: "center",
		maxWidth: "560px",
		paddingBottom: "20px"
	},
	accountPromptContainer: {
		"& h2": {
			marginBottom: "40px"
		},
		maxWidth: "560px",
		textAlign: "center",
		"& button": {
			width: "200px"
		}
	}
}));

export const LoginPage: FC = () => {
	const mq = useMq();
	const logo = mq.desktop ? "/logo.svg" : "/emblem.svg"
	const {classes} = useStyles();
	const {authenticated} = useContext(AuthContext);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [userExists, userExistsApi] = useApi<boolean | "Open Invite", string>();
	const [showAccountPrompt, setShowAccountPrompt] = useState<boolean>(false);

	useEffect(() => {
		if(!authenticated)
			return;
		const redirectPath = searchParams.get("redirectPath");
		const redirectSearch = searchParams.get("redirectSearch");

		navigate(redirectPath
			? {
				pathname: redirectPath,
				search: redirectSearch ?? ""
			} : "/courses");

	}, [authenticated]);

	const handleOnRegisterAction = (): void => {
		navigate(`/register/${location.search}`);
	}

	useEffect(() => {
		if(searchParams.get("redirectSearch")?.includes("token")){
			const token = searchParams
				.get("redirectSearch")
				?.split("&")
				?.find(e => e.includes("token"))
				?.replace("token=", "").replace("?", "");

			userExistsApi("/api/user/exists/" + token)
		}
	}, [])

	useEffect(() => {
		if(userExists.data === false){
			navigate(`/register/${location.search}`);
		}
		if(userExists.data === "Open Invite"){
			setShowAccountPrompt(true);
		}
	}, [userExists.data])

	return (
		<Box className={classes.loginPage}>
			<Box className={classes.container}>
				{userExists.isLoading
					? <LinearProgress />
					: showAccountPrompt
						? <Box className={classes.accountPromptContainer}>
							<Typography variant="h2" >UK Fire Door Training Academy</Typography>
							<Typography className={classes.copy}>You&apos;ve been invited to a group! <br/>You&apos;ll need to login to claim access, do you already have an account?.</Typography>
							<Box style={{display: "flex", gap: "20px", width: "420px", margin: "50px auto 50px auto"}}>
								<Button onClick={() => setShowAccountPrompt(false)}>Yes</Button>
								<Button onClick={() => handleOnRegisterAction()}>No</Button>
							</Box>

						</Box>
						: <LoginForm
							className={classes.loginForm}
							title="Login to UK Fire Door Training Academy"
							onLogin={() => {}}
							onForgotPasswordAction={() => navigate("forgotten-password")}
							onRegisterAction={handleOnRegisterAction}
						/>}
				{userExists.data === true && <Typography className={classes.copy}>You&apos;ve been invited to a group! Please sign in to claim.</Typography>}
				<Typography className={classes.copy}>If your account has been used to purchase anything from our shop, please use the same credentials {showAccountPrompt ? "" : "to login here"}.</Typography>
				<div className={classes.imgContainer}>
					<img src={logo} />
				</div>
			</Box>
		</Box>
	);
}