import {APIState, useApi} from "@plumeuk/shapeshift-identity";
import {APIRequestConfig} from "@plumeuk/shapeshift-common/types/ICommon";
import {IPortfolioQuestion} from "../../../../types/portfolio/IPortfolio";

type S = IPortfolioQuestion[];
type R = string | {populate: string[]};
type G = (courseSlugOverride?: string, portfolioSlugOverride?: string, configOverride?: APIRequestConfig<R>) => void;

export type IUsePortfolioReturnProp = {
	portfolioQuestions?: S,
	apiResult: APIState<S>,
	getPortfolioQuestions: G,
	setPortfolioQuestions: (portfolio: S) => void
}

const getPortfolioQuestionsUrl = (courseSlug: string, portfolioSlug: string): string => `/api/${courseSlug}/portfolios/${portfolioSlug}/questions`

export const usePortfolioQuestions = (courseSlug?:string, portfolioSlug?: string): IUsePortfolioReturnProp => {
	const [apiResult, getData, setPortfolio] = useApi<S>(!courseSlug || !portfolioSlug ? null : {url: getPortfolioQuestionsUrl(courseSlug, portfolioSlug)});

	const getPortfolioQuestions: G = (courseSlugOverride, portfolioSlugOverride, configOverride) => {
		const _portfolioSlug = portfolioSlugOverride ?? portfolioSlug;
		const _courseSlug = courseSlugOverride ?? courseSlug;
		if(!_portfolioSlug || !_courseSlug) return;

		getData(configOverride
			? {...configOverride, url: getPortfolioQuestionsUrl(_courseSlug, _portfolioSlug)}
			: getPortfolioQuestionsUrl(_courseSlug, _portfolioSlug));
	}

	return {portfolioQuestions: apiResult.data, apiResult, getPortfolioQuestions, setPortfolioQuestions: setPortfolio};
}