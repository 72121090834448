import {IModuleBase} from "@plumeuk/shapeshift-types/ICourse";
import {IPortfolioQuestionType} from "./IPortfolioEntity";
import {FileInfo} from "../FileInfo";

export interface IPortfolio extends IModuleBase {
	type: "portfolio"
}

export type IPortfolioQuestion = IPortfolioFileUploadQuestion & {type: string}

export interface IPortfolioFileUploadQuestion extends IPortfolioQuestionBase {
	type: "portfolioFilesUpload"
}

export interface IPortfolioQuestionBase {
	id: number,
	title: string,
	hint?: string,
	content?: string,
	type: IPortfolioQuestionType
}

export const isPortfolioFileUploadQuestion = (question: IPortfolioQuestion): question is IPortfolioFileUploadQuestion => {
	return question.type == "portfolioFilesUpload";
}

// Question Answers ---------------
export interface IPortfolioQuestionAnswerBase {
	questionId: number;
	questionType: IPortfolioQuestionType;
}

export type IPortfolioAnswer = IPortfolioFilesUploadQuestionAnswer;

export interface IPortfolioFilesUploadQuestionAnswer extends IPortfolioQuestionAnswerBase {
	files: FileInfo[];
	questionType: "portfolioFilesUpload";
	audits?: {
		id: number,
		fileId: number,
		approved: boolean
	}[]
}

export const isPortfolioFileUploadAnswer = (question: IPortfolioAnswer): question is IPortfolioFilesUploadQuestionAnswer => {
	return question.questionType == "portfolioFilesUpload";
}