import {Button, LinearProgress, Typography} from "@mui/material";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";
import {CourseCertificate} from "@plumeuk/shapeshift-common/course";
import {FC, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import ConfettiExplosion from "react-confetti-explosion";
import {useApi} from "@plumeuk/shapeshift-identity";
import {ICourseCertificateAddress, isCourseCertificateAddressValid} from "../../types/ICourseCertificateAddress";
import {CourseCertificateReactPDFDocument} from "./CertificateReactPdfDocument";

const useStyles = makeStyles()((theme) => ({
	centeredContainer: {
		[theme.breakpoints.down("sm")]: {
			paddingTop: "60px",
			"& [class*='centerTile']": {
				paddingLeft: 0,
				paddingRight: 0
			}
		},
		[theme.breakpoints.up("sm")]: {
			"& button": {
				width: "190px",
				height: "52px"
			}
		},
		textAlign: "center"
	},
	tagLine: {
		color: "#2A2A2F",
		fontWeight: 400
	},
	dashBoardBtn: {},
	file: {
		textAlign: "left",
		margin: "30px 0",
		[theme.breakpoints.down("sm")]: {
			background: "#BCBCBD33",
			textAlign: "inherit",
			"& > div:first-child": {
				display: "none"
			},
			"& h6": {
				fontSize: "22px"
			},
			"& [ class*='downloadButton']": {
				borderTop: "solid 2px #d1d1d180"
			}
		}
	},
	confetti: {
		border: "1px solid red",
		textAlign: "center",
		width: 0,
		margin: "auto",
		zIndex: "-1"
	}
}));

export const CourseCertificatePage: FC = () => {
	const {classes} = useStyles();
	const [isLoading, setIsLoading] = useState(true);
	const {courseSlug} = useParams();
	const navigate = useNavigate();
	const [isConfettiRunning, setIsConfettiRunning] = useState(true);
	const [{data: certDetails}, getAddress] = useApi<ICourseCertificateAddress>();

	const goToDashboardBtn = <Button onClick={() => navigate("/courses")} className={classes.dashBoardBtn}>Go to dashboard</Button>;
	const h = window.innerHeight;

	useEffect(() => {
		if(courseSlug)
			getAddress("/api/user/getCertificateAdress/" + courseSlug)
	}, [courseSlug])


	useEffect(() => {
		const timer = setTimeout(() => {
			setIsConfettiRunning(false);
		}, 4000);

		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		if(!certDetails) return;
		if(certDetails.deliveredCertificate === false || isCourseCertificateAddressValid(certDetails) && certDetails.confirmed){
			setIsLoading(false)
		}
		else {
			navigate(`/course/${courseSlug}/complete-review`);

		}
	}, [certDetails]);

	return (
		<>
			{isLoading
				? <LinearProgress />
				: <>
					<CenterTile
						className={classes.centeredContainer}
						title="Congratulations"
						icon={<WorkspacePremiumOutlinedIcon />}
						buttons={[goToDashboardBtn]}
					>
						<Typography variant="h6" className={classes.tagLine}>
							You finished the course and you have been awarded with a certificate. You can download it from the block below.
						</Typography>
						<CourseCertificate componentOverrides={{document: (cert) => <CourseCertificateReactPDFDocument certificate={cert} />}} courseSlug={courseSlug} />

					</CenterTile>
					{isConfettiRunning && <ConfettiExplosion className={classes.confetti} force={0.8} height={h} particleCount={250} duration={4000} />}
				</>}
		</>
	);
}