import {InputSelectProps} from "@plumeuk/shapeshift-common/form";
import {FC, useContext} from "react";
import {Box, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {palette} from "../constants";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import SchoolIcon from "@mui/icons-material/School";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import QuizIcon from "@mui/icons-material/Quiz";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {Link, useMatch} from "react-router-dom";

interface IStyleProps {
	pageIsMyLearning?: boolean,
	pageIsGroups?: boolean,
	pageIsLibrary?: boolean,
	pageIsAssessmentDashboard?: boolean
}

const useStyles = makeStyles<IStyleProps>()((theme, {pageIsMyLearning,pageIsGroups,pageIsLibrary, pageIsAssessmentDashboard}) => ({
	mobileSideMenu: {
		borderTop:"1px solid " + palette.grey,
		display: "flex",
		width: "100%",
		flexDirection: "column",
		"& > div": {
			width: "100%",
			borderBottom: "1px solid " + palette.grey,
			padding: "15px",
			paddingLeft: "45px"
		},
		"& svg": {
			marginRight: "15px",
			position: "relative",
			top: "5px"
		},
		"& a":{
			textDecoration: "none",
			color: "black"

		}
	},
	profile: {
		maxHeight: "310px",
		overflow: "hidden",
		margin: "20px",
		gap: "20px",
		display: "flex"

	},
	userIcon: {
		display: "flex",
		flexDirection: "column",
		textAlign: "center",
		justifyContent: "center",
		width: "80px",
		height: "80px",
		borderRadius: 100,
		fontSize: 30,
		boxSizing: "border-box",
		color: "white",
		backgroundColor: theme.navBar.statusprofile.backgroundColor
	},
	userName: {
		position: "relative",
		top: "27px"
	},
	myCourses: {

		"& svg":{
			color: pageIsMyLearning ? palette.purple : palette.darkerGrey
		}
	},
	library:{
		"& svg":{
			color: pageIsLibrary ? palette.purple : palette.darkerGrey
		}
	},
	groupsDashboard: {
		"& svg":{
			color: pageIsGroups ? palette.purple : palette.darkerGrey
		}
	},
	assessmentDashboard: {
		"& svg":{
			color: pageIsAssessmentDashboard ? palette.purple : palette.darkerGrey
		}
	}
}));

interface IProps extends InputSelectProps {
	value?: number,
	setSideMenuOpen?: (e: boolean) => boolean
}

export const MobileSideMenu: FC<IProps> = ({className, value, setSideMenuOpen, ...props}) => {
	const pageIsMyLearning = useMatch("/courses");
	const pageIsGroups = useMatch("/groups-dashboard");
	const pageIsLibrary = useMatch("/library");
	const pageIsAssessmentDashboard = useMatch("/assessment-dashboard");

	const {classes} = useStyles({
		pageIsMyLearning: !!pageIsMyLearning,
		pageIsGroups: !!pageIsGroups,
		pageIsLibrary: !!pageIsLibrary,
		pageIsAssessmentDashboard: !!pageIsAssessmentDashboard
	});
	const {user} = useContext(AuthContext);

	return (
		<>
			<Box className={classes.profile}>
				{user && user.firstname && <Box className={classes.userIcon}>
					{user?.firstname?.charAt(0)?.toUpperCase()}
					{user?.lastname?.charAt(0)?.toUpperCase()}
				</Box>}
				<Typography className={classes.userName} variant="h5">
					{user?.firstname} {user?.lastname}
				</Typography>
			</Box>
			<br />
			<Box className={classes.mobileSideMenu}>
				<Box className={classes.myCourses} onClick={() => setSideMenuOpen?.(false)}><Link to={"/courses"}><SchoolIcon /> My Courses</Link></Box>
				{/* <Box className={classes.library} onClick={() => setSideMenuOpen?.(false)}><Link to={"/library"}><LocalLibraryIcon />Library</Link></Box> */}
				{user && (user.isAuditor || user.isInstructor) &&
					<Box className={classes.assessmentDashboard} onClick={() => setSideMenuOpen?.(false)}><Link to={"/assessment-dashboard"}><QuizIcon />Assessment dashboard</Link></Box>
				}
				{(user as any)?.isOwner && <Box className={classes.groupsDashboard} onClick={() => setSideMenuOpen?.(false)}><Link to={"/groups-dashboard"}><PeopleAltIcon />Groups Dashboard</Link></Box>}
			</Box>
		</>

	);
}