import {InputSelect, InputSelectProps} from "@plumeuk/shapeshift-common/form";
import {FC} from "react";
import {MenuItem} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {palette} from "../constants";
import {useApi} from "@plumeuk/shapeshift-identity";
import {IGetBrandApiResponse} from "../types/IBrand";

const useStyles = makeStyles()((_theme) => ({
	brandSelect: {
		width: "100%",
		height: "100%",
		"& [class*='tagContainer'] button ": {
			background: palette.green,
			color: palette.black
		},
		"& [class*='tagContainer'] [class*='featuredTag']": {
			background: palette.pastelBlue,
			color: palette.purple
		}
	},
	pageBase: {
		background: palette.pastelBlue,
		"& [class*='courseLibrary'] > [class*='title'] ": {
			fontSize: "42px"
		}
	},
	search: {
		marginTop: "35px"
	},
	optionLogo: {
		borderRadius: "5px",
		width: "40px",
		paddingLeft: "15px"
	}
}));

interface IProps extends InputSelectProps {
	value?: number
}

export const BrandSelect: FC<IProps> = ({className, value, ...props}) => {
	const {classes, cx} = useStyles();
	const [brands] = useApi<IGetBrandApiResponse>({url: "/api/brands", data: {populate: ["logo"]}})

	return (
		<InputSelect
			data-test-id={"brand-select"}
			className={cx(classes.brandSelect, className)}
			value={value ?? -1}
			{...props}
		>
			<MenuItem key={"brands-all"} value={-1}>All Brands</MenuItem>
			{brands?.data?.data?.map((e, i) => (
				<MenuItem
					key={`brand_${i}`}
					value={e.id}
					data-test-id={`brand-select-option-${e}`}
				>
					{e.attributes.title}
					{e.attributes?.logo?.data?.attributes.url&& <img className={classes.optionLogo} src={e.attributes.logo.data.attributes.url ?? ""} />}
				</MenuItem>))}
		</InputSelect>
	);
}