import {GroupBanner, GroupEnrollmentTable, GroupInviteUser} from "@plumeuk/shapeshift-common/groups";
import {makeStyles} from "tss-react/mui";
import {useNavigate, useParams} from "react-router-dom";
import {INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {IOwnedGroup, IGroupBulkInvitationLink, IGroupInvitation} from "@plumeuk/shapeshift-types";
import {useContext, useEffect, useCallback} from "react";
import {Box, Button, CircularProgress, LinearProgress} from "@mui/material";

const useStyles = makeStyles()((_theme) => ({
	groupInviteUserButton: {
	},
	buySeatsBtn: {
		width: "150px",
		//Hidden pending woo-commerce product
		display: "none"
	},
	btnContainer: {
		marginTop: "50px",
		display: "flex",
		gap: "15px",
		"& > div": {
			margin: 0,
			padding: 0
		}
	}
}));

interface IProps {
	group?: IOwnedGroup,
	refreshGroup: () => void,
}

export const GroupPageContent: React.FC<IProps> = ({group, refreshGroup}) => {
	const {groupSlug} = useParams();
	const {classes} = useStyles();
	const navigate = useNavigate();
	const {notify} = useContext(NotificationContext);
	const [{data: ecommerceRedirect, isLoading}, getEccommerceRedirect] = useApi<{paymentUrl: string}>();

	const handleGoToCourse = (slug: string): void => {
		navigate(slug)
	}

	const handleGroupInviteResult = (invite: IGroupInvitation): void => {
		refreshGroup();
		notify(`User will receive a link in their emails, sent to ${invite.email}`, "Invite sent!", INotificationSeverity.success, 10000);
	}

	const handleGroupInviteRefreshResult = (invite: IGroupInvitation): void => {
		refreshGroup();
		notify(`User will receive a new link in their emails, sent to ${invite.email}`, "Invite refreshed!", INotificationSeverity.success, 10000);
	}

	const handleGroupBulkInviteResult = useCallback((invite: IGroupBulkInvitationLink): void => {
		refreshGroup();
		notify(invite.expiresAt ? `Expires on ${invite.expiresAt.toDateString()}` : "This invite will not expire", "Invite link created!", INotificationSeverity.success, 10000);
	}, []);

	const handleGroupInviteCancelationResult = (invite: IGroupInvitation): void => {
		refreshGroup();
		invite.email
			? notify(`User will receive an email, sent to ${invite.email}`, "Invite cancelled", INotificationSeverity.success, 10000)
			: notify("Users will no longer be able to sign up via the link", "Invite cancelled", INotificationSeverity.success, 10000);
	}

	const handleAddSeatsRequest = (): void => {
		getEccommerceRedirect({
			url: `/api/strapi-plugin-shapeshift-lms/${groupSlug}/orderAddSeats`,
			data: {
				callbackUrl: encodeURIComponent(window.location.href)
			}
		})
	}

	const handleGroupArchiveUserResult = (group: IOwnedGroup): void => {
		refreshGroup();
		notify("User will no longer recieve access to course materials via this group. You can restore their access via the archived members table.", "User archived!", INotificationSeverity.success, 10000);
	}

	const handleGroupRestoreUserResult = (group: IOwnedGroup): void => {
		refreshGroup();
		notify("User once again has access to course materials via this group. You can re-archive their access via the members table.", "User restored!", INotificationSeverity.success, 10000);
	}

	useEffect(() => {
		if(ecommerceRedirect)
			window.location.replace(ecommerceRedirect.paymentUrl);
	}, [ecommerceRedirect])

	return (
		group
			? <>
				<GroupBanner data={group} onGoToCourseAction={slug => handleGoToCourse(slug)}/>
				<GroupEnrollmentTable
					data={group}
					slug={groupSlug}
					onRefreshInvite={handleGroupInviteRefreshResult}
					onCancelInvite={handleGroupInviteCancelationResult}
					onArchiveUser={handleGroupArchiveUserResult}
					onRestoreUser={handleGroupRestoreUserResult}
				/>
				<Box className={classes.btnContainer}>
					<GroupInviteUser
						className={classes.groupInviteUserButton}
						groupSlug={groupSlug}
						onInvite={handleGroupInviteResult}
						onBulkInvite={handleGroupBulkInviteResult}
					/>
					<Button className={classes.buySeatsBtn} onClick={handleAddSeatsRequest}>{isLoading ? <CircularProgress size={25}/> : "Add seats"}</Button>
				</Box>

			</>
			: <LinearProgress/>
	);
}