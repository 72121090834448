import {Box, BoxProps, Typography} from "@mui/material";
import {FC, useContext, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {IconContext} from "@plumeuk/shapeshift-identity";
import {IPortfolioQuestion} from "../../../../../types/portfolio/IPortfolio";

interface IPropsCustom {
	question: IPortfolioQuestion,
}

export type IProps = BoxProps & IPropsCustom;

const useStyles = makeStyles<{ showHint: boolean }>()((theme, {showHint}) => ({
	questionHint: {
		display: "flex",
		gap: "5px"
	},
	label: {
		cursor: "pointer",
		color: theme.palette.secondary.main,
		fontWeight: 600
	},
	bodyContainer: {
		overflow: "hidden",
		transition: "all .5s ease-in",
		opacity: showHint ? 1 : 0,
		maxWidth: showHint ? "350px" : "0px",
		...(!showHint && {display: "none"})
	},
	body: {
		color: theme.palette.primary.main,
		width: "350px"
	}
}));

export const QuestionHint: FC<IProps> = ({className, question, ...props}) => {
	const [showHint, setShowHint] = useState(false);
	const {classes, cx} = useStyles({showHint});
	const {icon} = useContext(IconContext);

	if (!question.hint)
		return <></>;

	return (
		<Box
			className={cx(classes.questionHint, className)}
			{...props}
		>
			{icon("infoIcon", {
				className: classes.label
			})}
			<Typography
				data-test-id="question-hint-show-hint-btn"
				className={classes.label}
				onClick={() => setShowHint(prev => !prev)}
			>
				{showHint ? "Hide hint:" : "Show hint"}
			</Typography>
			<Box className={classes.bodyContainer}>
				<Typography className={classes.body}>{question.hint}</Typography>
			</Box>
		</Box>
	);
};
