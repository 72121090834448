import {makeStyles} from "tss-react/mui";
import {IOwnedGroup} from "@plumeuk/shapeshift-types";
import {Box, Button, LinearProgress, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import {OwnedGroupDataProvider} from "@plumeuk/shapeshift-common/providers";
import {GroupMembersTable} from "@plumeuk/shapeshift-common/groups";

const useStyles = makeStyles()((theme) => ({
	title: {
		marginBottom: "20px"
	},
	groupCourse: {
		padding: "25px 0"
	},
	backNavContainer: {
		display: "flex",
		gap: "5px",
		"& svg": {
			color: theme.palette.secondary.main
		},
		marginBottom: "100px"
	}
}));

interface IProps {
	group?: IOwnedGroup
}

export const GroupCourse: React.FC<IProps> = ({group}) => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const courseTitle = group?.courses?.find(e => e.slug === courseSlug)?.title;
	const navigate = useNavigate();

	return (
		group
			? <Box className={classes.groupCourse}>
				<Typography variant="h1" className={classes.title}>{group?.title}</Typography>
				<Typography variant="h3" className={classes.title}>{courseTitle}</Typography>
				<br />
				<Button onClick={() => navigate("../")} className={classes.backNavContainer}>
					<KeyboardReturnIcon />
					<Typography>Back to group</Typography>
				</Button>
				<OwnedGroupDataProvider slug={group.slug} courseSlug={courseSlug}>
					{({group: groupFiltered, apiResult}) => apiResult.isLoading ? <LinearProgress/> : <GroupMembersTable members={groupFiltered?.members}/>}
				</OwnedGroupDataProvider>
			</Box>
			: <LinearProgress />
	);
}