import {IFile} from "@plumeuk/shapeshift-types";

export interface ICourseCertificateAddress {
	addressType: "Group leader" | "Personal",
	address?: string,
	printedName: string,
	confirmed: boolean,
	idAttachment?: IFile,
	deliveredCertificate: boolean
}
export const isCourseCertificateAddressValid = (e: ICourseCertificateAddress): boolean => (!!e.printedName && (e.addressType === "Group leader" || !!e.address)
	&& !!e.idAttachment);