import {PageBase} from "../../components/pageBase/pageBase";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {MobileSideMenu} from "../../components/MobileSideMenu";
import AssessmentDashboardDataProvider from "../../providers/AssessmentDashboardDataProvider";
import {Box, LinearProgress, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@mui/material";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";
import {useNavigate} from "react-router-dom";
import {getStatusText} from "./getStatusText";
import {IPortfolioAttemptStatus} from "../../types/portfolio/IPortfolioAttempt";
import {makeStyles} from "tss-react/mui";
import {LearnerAutoCompleteInput} from "./LearnerAutoCompleteInput";
import {CourseAutoCompleteInput} from "./CourseAutoCompleteInput";
import {palette} from "../../constants";

const useStyles = makeStyles()((theme) => ({
	seachTermInput: {
		padding: 0,
		flexGrow: 3
	},
	dashboardHeader: {
		marginTop: "20px"
	},
	searchContainer: {
		display: "flex",
		gap: "15px",
		marginBottom: "15px",
		background: "transparent"
	},
	assessmentDashboardContainer: {
		paddingBottom: "90px"
	},
	tableHead: {
		[theme.breakpoints.down("md")]: {
			display: "none"
		}
	},
	mobileLabel: {
		fontWeight: "600",
		display: "none",
		margin: "2px 0",
		[theme.breakpoints.down("md")]: {
			display: "block"
		}
	},
	dashboardAssessment: {
		border: "1px solid #e3e3e3",
		borderRadius: "15px",
		display: "block",
		"& td": {
			"&:nth-last-child(1)": {
				[theme.breakpoints.up("md")]: {
					textAlign: "right"
				}
			}
		},
		[theme.breakpoints.down("md")]: {
			"& tr, & td, & tbody": {
				display: "block",
				height: "auto",
				textAlign: "left"
			},
			"& tr": {
				"&:nth-child(even)": {
					backgroundColor: palette.pastelBlue
				},
				"&:hover": {
					background: "inherit"
				}
			}
		}
	}
}));

export const AssessmentDashboardPage: React.FC = () => {
	const mq = useMq();
	const navigate = useNavigate();
	const {classes} = useStyles();

	return (
		<PageBase sideBarContent={mq.mobile ? <MobileSideMenu /> : undefined}>
			<AssessmentDashboardDataProvider>
				{({assessmentDashboardData, apiResult}, {setPage, setPageSize, setSearchTerm, setLearnerId, setCourseSlug}, {page, pageSize, searchTerm, learnerId, courseSlug}) => <>
					<Box className={classes.assessmentDashboardContainer}>
						<CenterTile className={classes.dashboardHeader} style={{maxWidth: "760px"}} title="Assessment Dashboard" />
						<Paper className={classes.searchContainer}>
							<TextField
								id="search"
								className={classes.seachTermInput}
								label="Search term"
								value={searchTerm}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									setSearchTerm(event.target.value);
								}}
							/>
							<LearnerAutoCompleteInput learnerId={learnerId} onChange={e => setLearnerId(e)} />
							<CourseAutoCompleteInput courseSlug={courseSlug} onChange={e => setCourseSlug(e)} />
						</Paper>
						<Table className={classes.dashboardAssessment}>
							<TableHead className={classes.tableHead}>
								<TableRow>
									<TableCell sx={{height: "30px"}} component="th">Portfolio</TableCell>
									<TableCell sx={{height: "30px"}} component="th">Status</TableCell>
									<TableCell sx={{height: "30px"}} component="th">Course</TableCell>
									<TableCell sx={{height: "30px"}} component="th">Learner</TableCell>
									<TableCell sx={{height: "30px"}} component="th">Last Submission Date</TableCell>
									<TableCell sx={{height: "30px"}} component="th">Submissions</TableCell>
									<TableCell sx={{height: "30px"}} component="th">Audits</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{apiResult.isLoading ? <LinearProgress /> : assessmentDashboardData?.data?.map((data) => (
									<TableRow sx={{cursor: "pointer"}} key={data.portfolio.id} hover onClick={() => navigate(`/assessment-dashboard/${data.course.slug}/${data.portfolio.slug}/${data.learner.id}`)}>
										<TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Portfolio
											</Box>
											{data.portfolio.title}
										</TableCell>
										<TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Status
											</Box>
											{getStatusText(data.portfolio.status as IPortfolioAttemptStatus)}
										</TableCell>
										<TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Course
											</Box>
											{data.course.title}
										</TableCell>
										<TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Learner
											</Box>
											{`${data.learner.firstname} ${data.learner.lastname}`}
										</TableCell>
										<TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Last submission
											</Box>
											{data.portfolio?.lastSubmissionDate ? new Date(data.portfolio.lastSubmissionDate).toLocaleDateString() : ""}
										</TableCell>
										<TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Submissions
											</Box>
											{data.submissions}
										</TableCell>
										<TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Audits
											</Box>
											{data.audits}
										</TableCell>
									</TableRow>))}
								{(!apiResult.isLoading && !assessmentDashboardData?.data.length) && (
									<TableRow>
										<TableCell sx={{height: "30px", textAlign: "center"}} colSpan={5}>No data found</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
						<Box sx={{display: "flex", mt: 2, alignItems: "center"}}>
							<div>
								<Select
									value={pageSize}
									label="Page Size"
									onChange={(e) => {
										setPageSize(typeof e.target.value === "string" ? parseInt(e.target.value) : e.target.value)
									}}
								>
									<MenuItem value={5}>5</MenuItem>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={20}>20</MenuItem>
									<MenuItem value={50}>50</MenuItem>
								</Select>
							</div>
							<Pagination size="large" page={page} count={Math.ceil((assessmentDashboardData?.totalCount ?? 0) / pageSize)} onChange={(_, p) => setPage(p)} />
						</Box>
					</Box>
				</>}
			</AssessmentDashboardDataProvider>
		</PageBase>
	);
}