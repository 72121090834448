import {FC, useEffect} from "react";
import {IUsePortfolioReturnProp, usePortfolio} from "../hooks/usePortfolio";
import {ICourseCurriculum} from "@plumeuk/shapeshift-types";
import {getModule} from "@plumeuk/shapeshift-common/common";
import {moduleTypeSlim} from "@plumeuk/shapeshift-types/ICourse";
import {IPortfolio} from "../types/portfolio/IPortfolio";

type S = IPortfolio;

export interface IProps {
	portfolioSlug?: string,
	courseSlug?: string,
	populate?: string[],
	children?: (e: IUsePortfolioReturnProp) => JSX.Element,
	data?: S,
	curriculum?: ICourseCurriculum
}

export const PortfolioDataProvider: FC<IProps> = ({children, courseSlug, portfolioSlug, data, populate, curriculum}) => {
	const dataHook = usePortfolio(courseSlug, portfolioSlug, data, populate)
	const {portfolio, getPortfolio, setPortfolio} = dataHook;

	useEffect(() => {
		if(portfolioSlug && courseSlug && !data)
			getPortfolio(courseSlug, portfolioSlug, populate ? {data: {populate}} : undefined);
	}, [portfolioSlug, courseSlug, populate])

	useEffect(() => {
		if(curriculum && portfolio){
			const module = getModule(curriculum, {slug: portfolio.slug, type: "portfolio" as moduleTypeSlim});
			if(module && module?.complete !== portfolio.complete){
				setPortfolio({...portfolio, complete: module?.complete})
			}
		}
	}, [curriculum])

	return children?.(dataHook)
		?? <div>Please implement children to handle portfolio {portfolio?.title ?? "...loading"}</div>
}

export default PortfolioDataProvider;