import {FC, useCallback, useEffect, useState} from "react";
import {Box, BoxProps, Button, LinearProgress} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {IMarkedQuizAnswers, IQuiz, IQuizAnswer, IQuizQuestion} from "@plumeuk/shapeshift-types";
import {QuizStartSummary} from "../QuizPage/QuizStartSummary/QuizStartSummary";
import {QuizQuestions} from "../QuizPage/QuizQuestions/QuizQuestions";
import {useParams} from "react-router-dom";
import {useApi} from "@plumeuk/shapeshift-identity";
import {QuizResult} from "../QuizPage/QuizQuestions/QuizResult/QuizResult";

const useStyles = makeStyles()((theme) => ({
	lessonPage: {
		paddingTop: "50px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	},
	quizStartSummary: {
		padding: 0,
		maxWidth: "none",
		textAlign: "left",
		"& [class*='centerTile']": {
			padding: 0
		}
	},
	quizResult: {
		"& [class*='nextBtn']": {
			display: "none"
		},
		"& [class*='buttonContainer'] .nextModuleBtn, [class*='buttonContainer'] .nextModuleBtn, [class*='buttonContainer'] .retakeBtn": {
			display: "none"
		}
	}
}));

interface IPropsCustom {
	quiz: IQuiz,
	autoStart?: boolean,
	onComplete: (result: IMarkedQuizAnswers) => void
}

type IProps = IPropsCustom & BoxProps;

export const MiniQuizzer: FC<IProps> = ({quiz, onComplete, autoStart, className, ...props}) => {
	const {classes, cx} = useStyles();
	const {courseSlug, moduleSlug: lessonSlug} = useParams();
	const [navState, setNavState] = useState<"summary" | "active" | "complete">(autoStart ? "active" : "summary");
	const [markQuizResult, markQuiz] = useApi<IMarkedQuizAnswers>()
	const [prevQuizResult] = useApi<IMarkedQuizAnswers>(`/api/strapi-plugin-shapeshift-lms/${courseSlug}/lessons/${lessonSlug}/quizzes/${quiz.slug}/latestResult`)
	const [quizResult, setQuizResult] = useState<IMarkedQuizAnswers>();
	const [quizQuestions] = useApi<IQuizQuestion[]>(`/api/strapi-plugin-shapeshift-lms/${courseSlug}/lessons/${lessonSlug}/quizzes/${quiz.slug}/questions`, [])

	const markAnswersActionOverride = useCallback((answers: IQuizAnswer[]) => {
		markQuiz({
			url: `/api/strapi-plugin-shapeshift-lms/${courseSlug}/lessons/${lessonSlug}/markQuiz/${quiz.slug}`,
			data: answers,
			method: "POST"
		})
		return true
	}, [])

	useEffect(() => markQuizResult.data && setQuizResult(markQuizResult.data), [markQuizResult])
	useEffect(() => prevQuizResult.data && setQuizResult(prevQuizResult.data), [prevQuizResult])

	useEffect(() => {
		if(quizResult){
			setNavState("complete")
			if(quizResult.pass)
				onComplete(quizResult);
		}
	}, [quizResult])

	return (
		<Box className={cx(classes.lessonPage, className)} {...props}>
			{navState === "summary" && <QuizStartSummary className={classes.quizStartSummary} quiz={quiz} onContinueActionOverride={() => {setNavState("active"); return true}} />}
			{navState === "active" && (quizQuestions.data ?? [])?.length > 0 && <QuizQuestions courseSlug={courseSlug} quizSlug={quiz.slug} markAnswersActionOverride={markAnswersActionOverride} quizQuestionDataOverride={quizQuestions.data}/>}
			{navState === "complete" && <QuizResult className={classes.quizResult} quiz={quiz} data={quizResult}/>}
			{navState === "complete" && quizResult && <Button onClick={() => setNavState("active")}>Retake</Button>}
			{(markQuizResult.isLoading || prevQuizResult.isLoading || quizQuestions.isLoading) && <Box padding={3}><LinearProgress /></Box>}
		</Box>
	);
}