export const sideMenuWidth = "400px";
export const toolBarHeight = "80px";

export const palette = {
	purple: "#D2007F",
	green: "#00D264",
	pastelBlue: "#F3F6FD",
	grey: "#27245B20",
	darkerGrey: "#D8D8D8",
	darkGrey: "grey",
	darkBlue: "#27245B",
	background: "#ffffff",
	success: "#00D264",
	error: "#FE4A49",
	black: "#000"
}

export const templateConstants = {
	containers: {
		constrained: "860px",
		standard: "1040px",
		wide: "1420px"
	},
	borderRadius: {
		small: "8px",
		medium: "16px",
		large: "100px"
	},
	thumbnails: {
		small: "150px",
		medium: "233px",
		large: "400px"
	},
	borderWidth: {
		small: "1px",
		medium: "2px",
		large: "4px"
	},
	transitions: {
		// To do: potentially create a variety of different transition properties and effects
		fast: "0.25s ease-in-out",
		medium: "0.5s ease-in-out",
		slow: "1s ease-in-out"
	}
}