import {APIState, useApi} from "@plumeuk/shapeshift-identity";
import {useEffect} from "react";
import {APIRequestConfig} from "@plumeuk/shapeshift-common/types/ICommon";
import {IPaginatedResponse} from "@plumeuk/shapeshift-types";
import {IPortfolioAdminEntry} from "../types/portfolio/IPortfolioAdminEntry";

type S = IPaginatedResponse<IPortfolioAdminEntry[]>;
type R = string | {populate: string[]};
type G = (configOverride?: APIRequestConfig<R>) => void;

export type IUsePortfolioAdminReviewEntriesProps = {
	assessmentDashboardData?: S,
	apiResult: APIState<S>,
	getAssessmentDashboardData: G,
	setAssessmentDashboardData: (assessmentDashboardData: S) => void
}


export const usePortfolioAdminReviewEntries = (page: number, pageSize: number, searchTerm: string, learnerId?: number, courseSlug?: string, data?: S): IUsePortfolioAdminReviewEntriesProps => {
	const [apiResult, getAssessmentDashboardData, setAssessmentDashboardData] = useApi<S>(null, data);

	useEffect(() => data && setAssessmentDashboardData(data), [data])

	useEffect(() => {
		if(!data){
			getAssessmentDashboardData({
				url: "/api/portfolios",
				data: {page, pageSize, searchTerm, learnerId, courseSlug}
			})
		}
	}, [page, pageSize, searchTerm, learnerId, courseSlug, data])

	return {assessmentDashboardData: apiResult.data, apiResult, getAssessmentDashboardData, setAssessmentDashboardData};
}