import {GroupTable} from "@plumeuk/shapeshift-common/groups";
import {CenteredContainer} from "../../components/pageBase/centeredContainer";
import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {useNavigate} from "react-router-dom";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {MobileSideMenu} from "../../components/MobileSideMenu";

const useStyles = makeStyles()((_theme) => ({
	sideMenu: {
		color: "black"
	},
	groupsTable: {
		"& [ class*='groupTile'] h4": {
			fontWeight: 600
		}
	}

}));


export const MyGroupsPage: React.FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const mq = useMq();

	return (
		<PageBase sideBarContent={mq.mobile ? <MobileSideMenu /> : undefined}>
			<CenteredContainer>
				<GroupTable className={classes.groupsTable} onGoToGroupAction={(slug) => navigate(`/groups-dashboard/${slug}`)}/>
			</CenteredContainer>
		</PageBase>
	);
}