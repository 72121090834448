import {FC, useContext} from "react";
import {useParams} from "react-router-dom";
import {Box, LinearProgress} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {ModuleNotAvailable} from "@plumeuk/shapeshift-common/module";
import {LessonDataProvider} from "@plumeuk/shapeshift-common/providers";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {LessonPageContent} from "./LessonPageContent";

const useStyles = makeStyles()((theme) => ({
	lessonPage: {
		paddingTop: "50px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	}
}));


export const LessonPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const {moduleSlug: lessonSlug} = useParams();
	const {curriculumState: curriculum} = useContext(CurriculumContext);

	return (
		<Box className={classes.lessonPage}>
			<LessonDataProvider courseSlug={courseSlug} lessonSlug={lessonSlug} curriculum={curriculum} populate={["files", "quiz"]}>
				{({lesson, apiResult}) => <>
					{apiResult.isLoading && <LinearProgress />}
					{apiResult.statusCode === 200 && <LessonPageContent lesson={lesson} courseSlug={courseSlug} lessonSlug={lessonSlug} />}
					{apiResult.statusCode === 403 &&
						<ModuleNotAvailable>
							{apiResult.errorData?.error.message}. We&apos;ll email you when it&apos;s available
						</ModuleNotAvailable>
					}
				</>}
			</LessonDataProvider>
		</Box>
	);
}