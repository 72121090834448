import {FC, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Button, CircularProgress, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {useApi} from "@plumeuk/shapeshift-identity";

const useStyles = makeStyles()((_theme) => ({
	btn: {
		margin: "25px",
		width: "140px",
		height: "50px"
	}
}));

export const QuizResetRequired: FC = () => {
	const {courseSlug, moduleSlug: quizSlug} = useParams();
	const {classes} = useStyles();
	const [{data: ecommerceRedirect, isLoading}, getEccommerceRedirect] = useApi<{paymentUrl: string}>();

	const handleRetryRequest = (): void => {
		getEccommerceRedirect({
			url: `/api/strapi-plugin-shapeshift-lms/${courseSlug}/quizzes/${quizSlug}/orderUnlock`,
			data: {
				callbackUrl: encodeURIComponent(window.location.href)
			}
		})
	}
	useEffect(() => {
		if(ecommerceRedirect)
			window.location.replace(ecommerceRedirect.paymentUrl);
	}, [ecommerceRedirect])

	return (
		<Box>
			<Typography variant="h3">
				Oh no!
			</Typography>
			<br />
			<Typography variant="body1">
				Sorry to hear you failed your assessment!<br/>
				Please follow the link below to purchase a retry token.
			</Typography>
			<Button className={classes.btn} onClick={() => handleRetryRequest()}>
				{isLoading ? <CircularProgress size={25}/> : "Buy retry"}
			</Button>
		</Box>
	);
}