import {FC} from "react";
import {Grid, GridProps, Typography} from "@mui/material";
import {palette} from "../../../constants";
import {makeStyles} from "tss-react/mui";
import {IPortfolioAttemptStatus} from "../../../types/portfolio/IPortfolioAttempt";

const useStyles = makeStyles()((theme) => ({
	portfolioReviewStatusBanner: {
		padding: "32px 40px 32px 32px",
		background: palette.pastelBlue,
		color: palette.darkBlue,
		borderRadius: "10px"
	},
	portfolioGridItem: {
		paddingTop: 0,
		margin: 0,
		display: "flex",
		alignContent: "center",
		flexFlow: "wrap",
		alignItems: "center"
	},
	portfolioGridTitle: {
		marginTop: 0,
		marginBottom: "20px",
		[theme.breakpoints.down("md")]: {
			marginTop: "20px"
		}
	},
	portfolioGridDescription: {
		opacity: "0.7"
	}
}));

interface IPropsCustom {
	status: IPortfolioAttemptStatus
}

export type IProps = GridProps & IPropsCustom;

export const PortfolioStatusBanner: FC<IProps> = ({className, status}) => {
	const {classes, cx} = useStyles();

	const config: { [key in IPortfolioAttemptStatus]: { title: string, text: string, image: string } } = {
		"awaiting-review": {
			title: "Your uploads are now under review by our assessors",
			text: "We'll let you know when your assessment feedback is available. Once you've reviewed your feedback, you can complete the course and claim your certificate.",
			image: "/portfolio-awaiting-review.png"
		},
		"approved": {
			title: "Portfolio Evaluation Complete",
			text: "Congratulations on completing the assessment! You're one step closer to finishing the course. Your feedback will be available soon.",
			image: "/portfolio-approved.png"
		},
		"rejected": {
			title: "Review Needed",
			text: "Your assessment has been reviewed, and we've provided some feedback to help you improve. Please review the comments and make any necessary changes before resubmitting.",
			image: "/portfolio-rejected.png"
		}
	}
	return (
		<>
			{config[status] && (
				<Grid className={cx(classes.portfolioReviewStatusBanner, className)} container>
					<Grid className={classes.portfolioGridItem} item xs={12} md={3}>
						<img alt="Portfolio Status" src={config[status].image} />
					</Grid>
					<Grid className={classes.portfolioGridItem} item xs={12} md={9}>
						<Typography className={classes.portfolioGridTitle} variant="h4">{config[status].title}</Typography>
						<Typography className={classes.portfolioGridDescription}>{config[status].text}</Typography>
					</Grid>
				</Grid>
			)}
		</>
	)
}