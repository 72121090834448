import {MarkQuizActionProvider, QuizQuestionsDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {CenteredContainer} from "../../../../components/pageBase/centeredContainer";
import {QuizQuestionContainer} from "./QuizQuestion/QuizQuestionContainer";
import {LinearProgress} from "@mui/material";
import {QuizResetRequired} from "../QuizResetRequired";
import {IQuizAnswer, IQuizQuestion} from "@plumeuk/shapeshift-types";
import {palette} from "../../../../constants";

const useStyles = makeStyles()((theme) => ({
	centeredContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		textAlign: "center",
		"& [ class*='questionHint'] [ class*='bodyContainer'] [ class*='body']": {
			color: palette.darkGrey
		},
		"& [ class*='questionOption'].active": {
			"& [ class*='optionId'] ": {
				color: "black"
			},
			borderColor: palette.purple
		}
	}
}));

interface IProps {
	courseSlug?: string,
	quizSlug?: string,
	markAnswersActionOverride?: (answers: IQuizAnswer[]) => true,
	quizQuestionDataOverride?: IQuizQuestion[]
}

export const QuizQuestions: FC<IProps> = (props) => {
	const {classes} = useStyles();
	const params = useParams();
	const courseSlug = props.courseSlug ?? params.courseSlug;
	const quizSlug = props.quizSlug ?? params.moduleSlug;

	return (
		<CenteredContainer className={classes.centeredContainer}>
			<MarkQuizActionProvider>
				{({markAnswers, response, apiResult: markAnswersApiResult}) =>
					<QuizQuestionsDataProvider courseSlug={courseSlug} quizSlug={quizSlug} data={props.quizQuestionDataOverride}>
						{({quizQuestions, apiResult}) => <>
							{apiResult.isLoading && <LinearProgress />}
							{!apiResult.isLoading && apiResult.isError && apiResult.statusCode === 403 && <QuizResetRequired />}
							{!markAnswersApiResult.isLoading && !apiResult.isLoading && !apiResult.isError && <QuizQuestionContainer
								questions={quizQuestions}
								onSubmit={(answers) => props.markAnswersActionOverride ? props.markAnswersActionOverride?.(answers) : markAnswers(courseSlug as string, quizSlug as string, answers)}
								submitResponseResultId={response}
							/>}
							{markAnswersApiResult.isLoading && <LinearProgress />}
						</>
						}
					</QuizQuestionsDataProvider>
				}
			</MarkQuizActionProvider>
		</CenteredContainer>
	);
}