import {Box, Typography} from "@mui/material";
import {FC, useContext, useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {RegisterForm} from "@plumeuk/shapeshift-common/register";
import {makeStyles} from "tss-react/mui";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import {palette} from "../../constants";

const logo = "/logo.svg"

const useStyles = makeStyles()((theme) => ({
	registerPage:{
		backgroundColor: theme.palette.background.default,
		minHeight: "100%",
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			backgroundColor: theme.palette.background.paper
		}
	},
	container: {
		marginTop: "15px",
		margin: "auto",
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column-reverse"
		}
	},
	imgContainer: {
		margin: "auto",
		textAlign: "center",
		width: "50%",
		[theme.breakpoints.down("sm")]: {
			margin: "30px 0",
			width: "100%"
		},
		"& img": {
			[theme.breakpoints.down("sm")]: {
			}
		}
	},
	registerForm: {
		marginTop: "15px"
	},
	copy: {
		color: palette.darkGrey,
		textAlign: "center",
		paddingBottom: "20px"
	},
	title: {
		marginBottom: "45px",
		display: "block",
		textAlign: "center",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			fontSize: "32px",
			lineHeight: "45px",
			padding: "0 30px",
			boxSizing: "border-box"
		}
	}
}));

export const RegisterPage: FC = () => {
	const {classes} = useStyles();
	const {authenticated} = useContext(AuthContext);
	const [searchParams] = useSearchParams();
	const token = searchParams
		.get("redirectSearch")
		?.split("&")
		?.find(e => e.includes("token"))
		?.replace("token=", "").replace("?", "");

	const navigate = useNavigate();

	useEffect(() => {
		if(!authenticated)
			return;
		const redirectPath = searchParams.get("redirectPath");
		const redirectSearch = searchParams.get("redirectSearch");

		navigate(redirectPath
			? {
				pathname: redirectPath,
				search: redirectSearch ?? ""
			} : "/courses");

	}, [authenticated]);

	return (
		<Box className={classes.registerPage}>
			<Box className={classes.container}>
				<RegisterForm
					componentOverrides={{title: <>
						<Typography variant="h2" className={classes.title}>{"Register to UK Fire Door Training Academy"}</Typography>
						{(token ?? "")?.length > 0 && <Typography className={classes.copy}>You&apos;ve been invited to a group! <br/>Please register to claim.</Typography>}

					</>}}
					className={classes.registerForm}
					title="Register to UK Fire Door Training Academy"
					token={token ?? undefined}
				/>
				<div className={classes.imgContainer}>
					<img src={logo} />
				</div>
			</Box>
		</Box>
	);
}