import {Box, BoxProps, Button, LinearProgress, Typography} from "@mui/material";
import {FC, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {QuizResultBreakdown} from "./QuizResultBreakdown";
import {QuizResultSummary} from "./QuizResultSummary";
import {MarkedQuizResultDataProvider} from "@plumeuk/shapeshift-common/providers";
import {useNavigate, useParams} from "react-router-dom";
import {IMarkedQuizAnswers, IQuiz} from "@plumeuk/shapeshift-types";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";
import {Error} from "@mui/icons-material";

const useStyles = makeStyles()((theme) => ({
	quizResult: {
		textAlign: "left",
		[theme.breakpoints.down("sm")]: {
			marginTop: "15px"
		}
	},
	markedAnswer: {
		marginBottom: "60px"
	},
	label: {
		color: "#A8A8A8"
	},
	title: {
		margin: "60px 0 20px 0"
	},
	subTitle: {
		marginBottom: "40px",
		color: "#A8A8A8"
	}
}));

interface IProps extends BoxProps {
	quiz?: IQuiz,
	data?: IMarkedQuizAnswers
}

export const QuizResult: FC<IProps> = ({quiz, data, className, ...props}) => {
	const {classes, cx} = useStyles();
	const [checkAnswers, setCheckAnswers] = useState(false);
	const {courseSlug, resultId, moduleSlug: quizSlug} = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		setCheckAnswers(false);
	}, [quiz]);

	return (
		<MarkedQuizResultDataProvider data={data} courseSlug={courseSlug} quizSlug={quizSlug} resultId={resultId}>
			{({markedQuizResult,apiResult}) =>
				<Box className={cx(classes.quizResult, className)} {...props}>
					{apiResult.isLoading && <LinearProgress />}
					{!apiResult.isError && markedQuizResult && (checkAnswers || !markedQuizResult.pass
						? <QuizResultBreakdown quiz={quiz} markedAnswers={markedQuizResult} />
						: <QuizResultSummary quiz={quiz} markedAnswers={markedQuizResult} onCheckAnswersAction={() => setCheckAnswers(true)}/>
					)}
					{apiResult.statusCode === 404 && quiz?.complete &&
						<CenterTile
							icon={<Error />}
							title={(quiz?.isAssessment ? "Assessment" : "Quiz") + " Complete"}
							subtitle="Click to proceed"
							buttons={[<Button onClick={() => navigate("/course/" + courseSlug)} key={1}>Next</Button>]}
						/>}
				</Box>
			}
		</MarkedQuizResultDataProvider>
	);
}
