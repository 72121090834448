import {API, APIState, useApi} from "@plumeuk/shapeshift-identity";
import {useEffect} from "react";
import {APIRequestConfig} from "@plumeuk/shapeshift-common/types/ICommon";
import {IPortfolio} from "../types/portfolio/IPortfolio";

type S = IPortfolio;
type R = string | {populate: string[]};
type G = (courseSlugOverride?: string, portfolioSlugOverride?: string, configOverride?: APIRequestConfig<R>) => void;

export type IUsePortfolioReturnProp = {
	portfolio?: S,
	apiResult: APIState<S>,
	getPortfolio: G,
	setPortfolio: (portfolio: S) => void
}

const getPortfolioUrl = (courseSlug: string, portfolioSlug: string): string => `/api/${courseSlug}/portfolios/${portfolioSlug}`

export const usePortfolio = (courseSlug?:string, portfolioSlug?: string, data?: S, populate?: string[]): IUsePortfolioReturnProp => {
	const [apiResult, getData, setPortfolio] = useApi<S>(data || !courseSlug || !portfolioSlug ? null : {url: getPortfolioUrl(courseSlug, portfolioSlug), data: {populate}}, data);

	const getPortfolio: G = (courseSlugOverride, portfolioSlugOverride, configOverride) => {
		const _portfolioSlug = portfolioSlugOverride ?? portfolioSlug;
		const _courseSlug = courseSlugOverride ?? courseSlug;
		if(!_portfolioSlug || !_courseSlug) return;

		getData(configOverride
			? {...configOverride, url: getPortfolioUrl(_courseSlug, _portfolioSlug)}
			: getPortfolioUrl(_courseSlug, _portfolioSlug));
	}

	useEffect(() => data && setPortfolio(data), [data])

	return {portfolio: apiResult.data, apiResult, getPortfolio, setPortfolio};
}