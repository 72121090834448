//TEMP: USE COMMON LIB VERSION - live patch

import React, {FC, useState, useEffect, useContext} from "react";
import {Box, BoxProps, Button, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {AuthContext, IForgetPasswordRequest, TForgottenPasswordRequest} from "@plumeuk/shapeshift-identity";
import {useValidate} from "@plumeuk/use-validate";
import {InputText} from "@plumeuk/shapeshift-common/inputText";
import {forgottenPasswordRequestValidationDoc} from "@plumeuk/shapeshift-common/validationDocs";

type IPropsCustom = {
	className?: string,
	title: string,
	actionOverrideForgottenPassword?: (e: IForgetPasswordRequest) => void,
}

export type IProps = BoxProps & IPropsCustom;

const useStyles = makeStyles()((theme) => ({
	ForgottenPasswordForm:{
		maxWidth: "400px",
		borderRadius: "10px",
		backgroundColor: theme.palette.background.paper,
		padding: "60px 80px",
		[theme.breakpoints.down("sm")]: {
			padding: "20px"
		}
	},
	title: {
		marginBottom: "45px",
		display: "block",
		textAlign: "center",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			fontSize: "32px",
			lineHeight: "45px",
			padding: "0 30px",
			boxSizing: "border-box"
		}
	},
	input: {
		marginTop: "10px"
	},
	buttonContainer: {
		marginTop: "40px",
		display: "flex",
		flexDirection: "column",
		gap: "20px",
		"& button": {
			width: "100%",
			height: "51px",
			[theme.breakpoints.down("sm")]: {
				fontSize: "15px"
			}
		}
	}
}));

export const ForgottenPasswordForm: FC<IProps> = ({className, title, actionOverrideForgottenPassword, ...props}) => {
	const {classes, cx} = useStyles();
	const [formData, setFormData] = useState<IForgetPasswordRequest>(TForgottenPasswordRequest);
	const [validationResult, runValidate, hasErrors, _clearValidation] = useValidate<IForgetPasswordRequest>(forgottenPasswordRequestValidationDoc, formData);
	const [requestSent, setRequestSent] = useState(false);

	const {forgottenPassword} = useContext(AuthContext);

	const handleSubmit = async (): Promise<void> => {
		actionOverrideForgottenPassword ? actionOverrideForgottenPassword?.(formData) : await forgottenPassword(formData.email);
		setRequestSent(true);
	}

	useEffect(() => {
		setFormData(TForgottenPasswordRequest);
	}, []);

	const input = (label: string, prop: keyof typeof TForgottenPasswordRequest, type: React.HTMLInputTypeAttribute): JSX.Element => (
		<InputText
			data-test-id={`forgotten-password-input-${label.toLocaleLowerCase().replaceAll(" ","")}`}
			className={classes.input}
			label={label}
			type={type}
			autoCapitalize="off"
			value={formData[prop]}
			error={validationResult[prop]}
			onChange={e => setFormData(prev => ({...prev, [prop]: e.target.value}))}
			onValidate={() => runValidate(prop)}
		/>
	)

	return (
		<Box className={cx(classes.ForgottenPasswordForm, className)} {...props}>
			<Typography variant="h2" className={classes.title}>{title}</Typography>
			{!requestSent && <>
				{input("Email or username", "email", "text")}
				<Box className={classes.buttonContainer}>
					<Button
						data-test-id="forgotten-password-form-send-email-btn"
						disabled={hasErrors}
						onClick={() => handleSubmit()}
					>
						Send Email
					</Button>
				</Box>
			</>}
			{requestSent && <Typography variant="body1">An email has been sent to {formData.email} with a link to reset your password.</Typography>}
		</Box>
	);
}

export default ForgottenPasswordForm;