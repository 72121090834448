import {Box, Typography} from "@mui/material"
import {useFileBaseUrl} from "@plumeuk/shapeshift-common/hooks";
import {IFile, ILibraryItem} from "@plumeuk/shapeshift-types";
import {FC} from "react"
import {makeStyles} from "tss-react/mui";

interface IProps {
	course: ILibraryItem
}

const useStyles = makeStyles()((theme) => ({
	content: {
		background: theme.palette.background.paper,
		padding: "15px",
		flexGrow: 2,
		position: "relative"
	},
	title: {
		fontWeight: 500
	},
	description: {
		color: "#2A2A2F66",
		marginTop: "15px"
	},
	brandLogoContainer:{
		maxWidth: "200px",
		borderTopRightRadius: "10px",
		position: "absolute",
		height: "40px",
		background: "white",
		overflow: "hidden",
		top: "-40px",
		left: "-5px",
		"& img": {
			padding: "5px",
			height: "80%"
		}
	}
}));


export const LibraryItemContent:FC<IProps> = ({course}) => {
	const {classes} = useStyles();
	const {getUrl} = useFileBaseUrl();
	const customCourse = course as ILibraryItem & {brand: {logo: IFile}};
	const logoURL = customCourse?.brand?.logo?.url;

	return (
		<Box className={classes.content}>
			{logoURL && <Box className={classes.brandLogoContainer}><img src={getUrl(logoURL)}/></Box>}
			<Typography className={classes.title}>{course.title}</Typography>
			<Typography className={classes.description}>{course.description}</Typography>
		</Box>
	)
}