import {Box, Button, LinearProgress, Typography} from "@mui/material";
import {useJoinGroup} from "@plumeuk/shapeshift-common/hooks";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";
import {FC, useContext, useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {PageBase} from "../../components/pageBase/pageBase";
import {palette} from "../../constants";

const useStyles = makeStyles()((theme) => ({
	groupSignUpPage:{
		backgroundColor: theme.palette.background.default,
		minHeight: "100%",
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			backgroundColor: theme.palette.background.paper
		}
	},
	container: {
		marginTop: "15px",
		margin: "auto",
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column-reverse"
		}
	},
	label: {
		margin: "15px",
		color: palette.darkBlue
	},
	error: {
		color: palette.error
	}
}));

export const GroupSignUpPage: FC = () => {
	const {classes, cx} = useStyles();
	const {joinGroup, apiResult} = useJoinGroup();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const {notify} = useContext(NotificationContext);
	const [loading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | JSX.Element>();

	//will make call to api
	useEffect(() => {
		const token = searchParams.get("token");

		if(!token){
			setError("Invalid redirect, please check the url");
			setIsLoading(false);
			return;
		}

		joinGroup(token)
	}, [])

	useEffect(() => {
		if(apiResult.statusCode === 200){
			notify("Successfully signed up to group", "", INotificationSeverity.success, 10000)
			navigate("/");
		}
		else if(apiResult.statusCode === 400){
			if(apiResult.errorData?.error.message.includes("already a member")){
				notify("Already a member of the group!", "", INotificationSeverity.warning, 10000)
				navigate("/");
			}
			else {
				setError(<>
					Invalid token! <br />
					Please check the link. It may have been expired, used or deleted. <br />
				</>)
			}
		}
	}, [apiResult])

	return (
		<PageBase>
			<Box className={classes.groupSignUpPage}>
				<Box className={classes.container}>
					{loading && !error && <>
						<LinearProgress />
						<Typography className={classes.label}>Signing up to group</Typography>
					</>}
					{error && <>
						<Typography className={cx(classes.label, classes.error)}>{error}</Typography>
						<Link to="/courses">
							<Button>Go to my learning</Button>
						</Link>
					</>}
				</Box>
			</Box>
		</PageBase>
	);
}